import React, { useEffect, useState } from 'react'
import AddFAQModal from './Modal/AddFAQ';
import { deleteFaqById, getCustomerAppFaqCrm } from '../../../services/private/customerApp.service';
import ConfirmDeletion from '../../../components/Modals/ConfirmDeletion';
import { toast } from 'react-toastify';
import ConfirmDeletionCustom from '../../../components/Modals/ConfirmDeletionCustom';

const FAQs = () => {
    const [allFaqs, setAllFaqs] = useState([]);
    const [selectedFaqIndex, setSelectedFaqIndex] = useState('');
    const [showFAQModal, setShowFAQModal] = useState(false);
    const [selectedFaq, setSelectedFaq] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const getFAQs = async () => {
        const res = await getCustomerAppFaqCrm();
        if (res.data.status === 200) {
            setAllFaqs(res.data.data)
        }
    }

    useEffect(() => {
        getFAQs();
    }, [])

    const handleDelete = async () => {
        const res = await deleteFaqById(selectedFaq?.uuid);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getFAQs();
            setShowDeleteModal(false);
        }
    }

    return (
        <div className="users w-100 h-full flex flex-col">
            <div className="settings-title">
                <div className="row d-flex d-flex flex-md-fill flex-wrap">
                    <div className="col-md-7 col-6 d-flex flex-md-fill flex-column">
                        <h1 className="fz24 !mb-0 black fw-po-medium">FAQs</h1>
                    </div>
                    <div className="col-md-5 col-6 d-flex justify-content-end align-items-md-end aling-items-center d-flex flex-md-fill">
                        <div className="">
                            <button onClick={() => {
                                setShowFAQModal(true)
                            }
                            } className="pr-btn flex">
                                <svg className="mr4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <mask id="path-1-inside-1_1117_451" fill="white">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" />
                                    </mask>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" fill="white" />
                                    <path d="M12.6665 11.3335H9.66653V14.3335H12.6665V11.3335ZM12.6665 12.6668V9.66678H9.66653V12.6668H12.6665ZM11.3332 12.6668H14.3332V9.66678H11.3332V12.6668ZM11.3332 11.3335V14.3335H14.3332V11.3335H11.3332ZM12.6665 14.3335H17.3331V8.33347H12.6665V14.3335ZM17.3331 14.3335C16.0444 14.3335 14.9998 13.2888 14.9998 12.0001H20.9998C20.9998 9.97509 19.3581 8.33347 17.3331 8.33347V14.3335ZM14.9998 12.0001C14.9998 10.7115 16.0444 9.66678 17.3331 9.66678V15.6668C19.3581 15.6668 20.9998 14.0252 20.9998 12.0001H14.9998ZM17.3331 9.66678H12.6665V15.6668H17.3331V9.66678ZM9.66653 12.6668V17.3333H15.6665V12.6668H9.66653ZM9.66653 17.3333C9.66653 16.0447 10.7112 15 11.9999 15V21C14.0249 21 15.6665 19.3584 15.6665 17.3333H9.66653ZM11.9999 15C13.2885 15 14.3332 16.0447 14.3332 17.3333H8.33322C8.33322 19.3584 9.97484 21 11.9999 21V15ZM14.3332 17.3333V12.6668H8.33322V17.3333H14.3332ZM11.3332 9.66678H6.66665V15.6668H11.3332V9.66678ZM6.66665 9.66678C7.95533 9.66678 9 10.7115 9 12.0001H3C3 14.0252 4.64162 15.6668 6.66665 15.6668V9.66678ZM9 12.0001C9 13.2888 7.95533 14.3335 6.66665 14.3335V8.33347C4.64162 8.33347 3 9.97509 3 12.0001H9ZM6.66665 14.3335H11.3332V8.33347H6.66665V14.3335ZM14.3332 11.3335V6.6669H8.33322V11.3335H14.3332ZM14.3332 6.6669C14.3332 7.95557 13.2885 9.00024 11.9999 9.00024V3.00024C9.97484 3.00024 8.33322 4.64186 8.33322 6.6669H14.3332ZM11.9999 9.00024C10.7112 9.00024 9.66653 7.95557 9.66653 6.6669H15.6665C15.6665 4.64186 14.0249 3.00024 11.9999 3.00024V9.00024ZM9.66653 6.6669V11.3335H15.6665V6.6669H9.66653Z" fill="white" mask="url(#path-1-inside-1_1117_451)" />
                                </svg>
                                Add FAQ</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='!px-5 !h-[88vh] overflow-auto'>
                {
                    allFaqs.length > 0 && allFaqs.map((faq, index) => (
                        <div id="accordion-collapse" data-accordion="collapse" key={`${index}`} className='mt-3'>
                            <div className='flex items-center'>
                                <h2 id={`accordion-collapse-heading-${index + 1}`} className='text-lg mb-0 w-full'>
                                    <button
                                        onClick={() => {
                                            if (index === selectedFaqIndex) {
                                                setSelectedFaqIndex('')
                                            } else {
                                                setSelectedFaqIndex(index)
                                            }
                                        }}
                                        type="button" className="flex items-center justify-between w-full p-2 font-medium rtl:text-right  border border-gray-200 rounded-lg focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                        <span>{faq.question}</span>
                                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                                        </svg>
                                    </button>
                                </h2>
                                <div className="dropdown view-more ">
                                    <button
                                        className=" border-0 !w-[50px] !ml-2 !h-7 rounded-lg hover:bg-black100 flex items-center justify-center"
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z"
                                                fill="#B5B5BE"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z"
                                                fill="#B5B5BE"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                // clipRule="evenodd"
                                                d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z"
                                                fill="#B5B5BE"
                                            />
                                        </svg>
                                    </button>
                                    <div
                                        className="dropdown-menu dropdown-menu-right w-auto !px-2"
                                        aria-labelledby="dropdownMenuButton"
                                    >

                                        <a
                                            className="dropdown-item"
                                            data-toggle="modal"
                                            data-target="#exampleModal"
                                            onClick={
                                                () => {
                                                    setSelectedFaq(faq);
                                                    setShowFAQModal(true);
                                                }
                                            }
                                        >
                                            Edit FAQ
                                        </a>
                                        <a
                                            className="dropdown-item del-menu-itme"
                                            data-toggle="modal"
                                            data-target="#exampleModal"
                                            onClick={
                                                () => {
                                                    setSelectedFaq(faq);
                                                    setShowDeleteModal(true);
                                                }
                                            }
                                        >
                                            Delete FAQ
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div id={`accordion-collapse-heading-${index + 1}`} className={selectedFaqIndex === index ? 'block !mt-3 w-[95%]' : "hidden"} aria-labelledby={`accordion-collapse-heading-${index + 1}`}>
                                <div class="p-2 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 w-full rounded-lg">
                                    <p class="mb-2  dark:text-gray-400 text-lg w-full" dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                                    {/* <p class="text-gray-500 dark:text-gray-400">Check out this guide to learn how to <a href="/docs/getting-started/introduction/" class="text-blue-600 dark:text-blue-500 hover:underline">get started</a> and start developing websites even faster with components on top of Tailwind CSS.</p> */}
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            {showFAQModal && <AddFAQModal
                show={showFAQModal}
                closeModal={() => {
                    setShowFAQModal(false)
                    setSelectedFaq({});
                }}
                getAllFaqs={getFAQs}
                selectedFaq={selectedFaq}
            />}
            {
                showDeleteModal && <ConfirmDeletionCustom
                    show={showDeleteModal}
                    setShow={setShowDeleteModal}
                    deleteText={`FAQ`}
                    deleteHandler={() => handleDelete()}
                />
            }
        </div>
    )
}

export default FAQs