import { getCompaniesForAnalytics } from "../services/private/company.service";

export const API_METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const API_URLS = {
  baseurl: process.env.REACT_APP_DEV_API_URL,
  lead: {
    getLeadDetail: "/leads/getLeadDetail",
    getActivityStatsByLeadUUID: "/leads/getActivityStatsByLeadUUID",
    getLatestActivityByLeadUUID: "/leads/getLatestActivityByLeadUUID",
    updateByKeyValue: "/leads/update",
    getTimeline: "/leads/getTimeline",
    filter: "/leads/filter/value",
    getNextandPreviousLead: "/leads/getNextandPreviousLead",
    markleadAsDead: "/leads/update",
    deleteLead: "/leads/deleteLead",
    reassignLead: "/leads/reassignLead",
    mergeLead: "/leads/mergeLead",
    searchLeadByName: "/leads/searchLeadByName",
    getActivityStatsByLeadUUID: "/leads/getActivityStatsByLeadUUID",
    getAllNotes: "/notes/getAllByLeadUUID",
    bookingDone: "/bookingDone/insert",
    getProjectsByLead: "/leads/getProjectsByLead",
    insertEoiDone: "/eoiDone/insert",
    insertBookingDone: "/bookingDone/insert",
    getCampaignInfo: '/leads/getCampaignInfo',
    getProjectsForFilter: '/leads/getProjectsForFilter',
    saveLeadAccess: '/leads/saveLeadAccess',
    getLeadAccess: '/leads/getLeadAccess',
    getLeadStatusShareConfig: '/leads/getLeadStatusShareConfig',
    saveLeadStatusShareConfig: '/leads/saveLeadStatusShareConfig',
    getLeadStatusShareConfigByStatusId: '/leads/getLeadStatusShareConfigByStatusId',
    getLeadStatusAssignedToConfigByStatusId: '/leads/getLeadStatusAssignedToConfigByStatusId',
    bulkLeadShare: '/leads/bulkLeadShare',
    bulkSourceChange: '/leads/bulkSourceChange',
    checkLeadExistence: '/leads/checkLeadExistence',
    getAdAnalytics: '/facebook/getAdAnalytics',
    knowlarityClickToCall: '/knowlarityIvr/knowlarityClickToCall',
    knowlarityClickToCallAdvanced: '/knowlarityIvr/knowlarityClickToCallAdvanced',
    saveLeadStatusSharedWithConfig: '/leads/saveLeadStatusSharedWithConfig',
    saveLeadStatusAssignedToConfig: '/leads/saveLeadStatusAssignedToConfig'
  },
  company: {
    getMasterEums: "/company/getStatusAndIntents",
    getConfig: "/company/config",
    getLeadReEngagedSources: '/leads/getLeadReEngagedSources',
    getLeadCounts: '/leads/getLeadCounts',
    getUsers: "/company/getUsers",
    addUsers: "/company/inviteUser",
    getUserProfile: "/company/getUserProfile",
    getUsersForSettings: "/company/getUsersForSettings",
    getProjects: "/company/getProjects",
    getAllProjects: "/company/getAllProjectsByCompanyId",
    getRoles: "/company/getRoles",
    updateCompanyInfo: "/company/update",
    updateCompanyInfoByUUID: "/company/updateCompanyByUUID",
    makeCompanyPremiumByUUID: "/company/makeCompanyPremium",
    getDashboardCounts: "/company/getDashboardCounts",
    inviteUser: "/company/inviteUser",
    editUser: "/company/updateUserByUUID",
    deleteUser: "/company/markUserInactiveByUUID",
    deleteUserByUuid: "/company/deleteUserByUUID",
    markUserActiveByUuid: "/company/markUserActiveByUUID",
    markUserInactiveByUuid: "/company/markUserInactiveByUUID",
    resetUserPassword: "/company/resetUserPassword",
    updatePassword: "/company/updatePassword",
    getDetails: "/company/getDetails",
    insertProject: "/company/insertProject",
    getProject: "/company/getProject",
    editProject: "/company/updateProjectByUUID",
    getPersonalInfo: "/company/getCompanyOwnerProfile",
    getUserInfo: "/company/getUserProfile",
    updateOwnerByKeyAndValue: "/company/updateProfileByKeyValue",
    addProjectFormId: "/company/addProjectFormId",
    updateProjectMarketingAgency: "/company/updateFormIntegrationMarketingAgency",
    getMakeIntegrationRouting: '/company/getMakeIntegrationRouting',
    getProjectFormIds: "/company/getProjectFormIds",
    getAllApikeys: "/company/getAllApikeys",
    deleteProjectFormId: "/company/markInActiveByUUID",
    updateRolePermissions: "/company/updateRolePermissions",
    getRolePermissions: "/company/getRolePermissions",
    getLandingPage: '/company/getLandingPageIntegrations',
    addPageIntegration: '/company/createLandingPageIntegration',
    updatePageIntegration: '/company/updateLandingPageIntegration',
    markInactive: '/company/markLandingPageIntegrationInActive',
    markActive: '/company/markLandingPageIntegrationActive',
    areaByName: '/public/getAreaByName',
    deleteProjectByUuid: "/company/deleteProjectByUUID",
    generateApiKey: "/company/generateApiKey",
    projectIntergration: '/company/checkProjectIntegration',
    markRoutingEnable: '/company/enableRouting',
    markRoutingDisable: '/company/disableRouting',
    getAllCompanyAgency: '/company/getAllMarketingAgencies',
    insertAgency: '/company/insertMarketingAgency',
    updateAgency: '/company/updateMarketingAgency',
    getExportLimit: '/company/getExportLimit',
    getSubscriptionExpiry: '/company/getSubscriptionExpiry',
    updateReEngagement: '/company/updateReEngagement',
    getReEngagementStatus: '/company/getReEngagementStatus',
    saveColumnPreference: '/company/saveColumnPreference',
    getColumnPreferenceById: '/company/getColumnPreference',
    getUserColumnPreferences: '/company/getColumnPreference',
    saveColumnPreferenceNew: '/company/saveColumnPreferenceNew',
    getColumnPreferenceNew: '/company/getColumnPreferenceNew',
    getCurrentUserColumnPreference: '/company/getColumnPreferenceNew',
    updateSmsNotification: '/company/updateSmsNotification',
    getAllDevelopers: '/company/getAllDevelopers',
    getUsersForProjectLead: '/company/getUsersForProjectLead',
    getUsersForProjectRouting: '/company/getUsersForProjectRouting',
    addUserToProjectRouting: '/routingConfig/addUserToProjectRouting',
    removeUserFromProjectRouting: '/routingConfig/removeUserFromProjectRouting',
    setProjectLead: '/projects/setProjectLead',
    getProjectLead: '/projects/getProjectLead',
    updateAlternateNumberDuplicacy: '/company/updateAlternateNumberDuplicacy',
    getLandingPageIntegrationRouting: '/company/getLandingPageIntegrationRouting',
    updateNewLeadNotification: '/company/updateNewLeadNotification',
    updateSiteVisitNotification: '/company/updateSiteVisitNotification',
    getAllSourcesData: '/company/getAllSourcesData',
    addCustomSource: '/company/addCustomSource',
    deleteSource: '/company/deleteSource',
    renameSource: '/company/renameSource',
    getCompaniesForAnalytics: '/company/getCompaniesForAnalytics',
    addBrokerCompany: '/company/addBrokerCompany',
    updateBrokerCompany: '/company/updateBrokerCompany',
    getMicromarket: '/company/getMicromarket',
    addBrokerUser: '/company/addBrokerUser',
    editBrokerUser: '/company/editBrokerUser',
    getAllBrokersUnderRm: '/company/getAllBrokersUnderRm',
    editBrokerCode: '/leads/editBrokerCode',
    removeLeadTag: '/leads/removeLeadTag',
    updateRm: '/company/updateRm',
    insertNotificationDevice: '/notifications/insertNotificationDevice',
    removeDeviceByFirebaseToken: '/notifications/removeDeviceByFirebaseToken',
    getMyUsers: '/company/getMyUsers',
    getUniqueFacebookForms: '/facebook/getUniqueFacebookForms',
    getAllRequests: '/plusApp/getAllRequests',
    updateStatusByUUID: '/plusApp/updateStatusByUUID',
    updateRemarksByUUID: '/plusApp/updateRemarksByUUID',
    saveCallRequestedNote: '/plusApp/saveCallRequestedNote',
    updateCallRequestedNote: '/plusApp/updateCallRequestedNote',
    getCallReqeustedNotes: '/plusApp/getCallReqeustedNotes',
    saveCompanyNote: '/plusApp/saveCompanyNote',
    updateCompanyNote: '/plusApp/updateCompanyNote',
    getCompanyNotes: '/plusApp/getCompanyNotes',
    addBanner: '/plusApp/addBanner',
    getAllBanners: '/plusApp/getAllBanners',
    updateBannerByUUID: '/plusApp/updateBannerByUUID',
    getBannerByUUID: '/plusApp/getBannerByUUID',
    makeCompanyFreemium: '/company/makeCompanyFreemium',
    updateBannerVisibilityByUUID: '/plusApp/updateBannerVisibilityByUUID',

    // sv tracker column customisation
    getMasterColumnPreferenceForSvTracker: '/company/getMasterColumnPreferenceForSvTracker',
    getColumnPreferenceForSvTracker: '/company/getColumnPreferenceForSvTracker',
    saveColumnPreferenceForSvTracker: '/company/saveColumnPreferenceForSvTracker',

    insertProjectLatestUpdates: '/projects/listings/insertProjectLatestUpdates',
    getProjectLatestUpdates: '/projects/listings/getProjectLatestUpdates',
    updateProjectLatestUpdates: '/projects/listings/updateProjectLatestUpdates',
    deleteProjectLatestUpdates: '/projects/listings/deleteProjectLatestUpdates',

    // Launchpad 
    getEventDetail: '/launchpad/getEventDetail',
    insertEvent: '/launchpad/insertEvent',
    updateEventByUUID: '/launchpad/updateEventByUUID',
    getAllEvents: "/launchpad/getAllEvents",
    updateEventStatusByUUID: 'launchpad/updateEventStatusByUUID',
    submitEventForReview: '/launchpad/submitEventForReview',

    // Automation 
    getSvCompletedReadAccess: '/company/getSvCompletedReadAccess',
    updateSvCompletedReadAccess: '/company/updateSvCompletedReadAccess',
    updateAutoLeadShare: '/company/updateAutoLeadShare',
    updateAutoUnassignedLeadDistribution: '/company/updateAutoUnassignedLeadDistribution',
    saveUnassignedLeadDistributionUsers: '/company/saveUnassignedLeadDistributionUsers',
    getUnassignedLeadDistributionUsers: '/company/getUnassignedLeadDistributionUsers',
    updateAutoPendingFollowupAssignment: '/company/updateAutoPendingFollowupAssignment',
    getPendingFollowupAssignment: '/company/getPendingFollowupAssignment',
    savePendingFollowupAssignment: '/company/savePendingFollowupAssignment',
    updateAutoEoiCancel: '/company/updateAutoEoiCancel',
    updateAutoCancelledEoiAssignment: '/company/updateAutoCancelledEoiAssignment',
    saveCancelledEoiAssignmentUsers: '/company/saveCancelledEoiAssignmentUsers',
    getCancelledEoiAssignmentUsers: '/company/getCancelledEoiAssignmentUsers',
    getUnassignedLeadProjectDistribution: '/leads/getUnassignedLeadProjectDistribution',
    saveUnassignedLeadProjectDistribution: '/leads/saveUnassignedLeadProjectDistribution',
    updateAutoUnassignedLeadProjectDistribution: '/company/updateAutoUnassignedLeadProjectDistribution',
    updateAutoDeadLeadToNewLead: '/company/updateAutoDeadLeadToNewLead',
    insertFinalNegotiationAutomationTeams: '/company/insertFinalNegotiationAutomationTeams',
    getFinalNegotiationAutomationTeams: '/company/getFinalNegotiationAutomationTeams',
    updateFinalNegotiationAutomationTeamsByUUID: '/company/updateFinalNegotiationAutomationTeamsByUUID',
    updateFinalNegotationAutomation: '/company/updateFinalNegotationAutomation',
    updateFinalNegotiationColdIntentAutomation: '/company/updateFinalNegotiationColdIntentAutomation',
    // insertFinalNegotiationAutomationTeams:'/company/insertFinalNegotiationAutomationTeams',
    // getFinalNegotiationAutomationTeams:'/company/getFinalNegotiationAutomationTeams',
    updateFinalNegotationAutomationTeamStatusByUUID: '/company/updateFinalNegotationAutomationTeamStatusByUUID',
    insertFinalNegotiationAutomationWithoutTeam: '/company/insertFinalNegotiationAutomationWithoutTeam',
    getFinalNegotationAutomationWithoutTeamConfig: '/company/getFinalNegotationAutomationWithoutTeamConfig',
    deleteFinalNegotationAutomationTeamsConfigByUUID: '/company/deleteFinalNegotationAutomationTeamsConfigByUUID',
    updateFinalNegotationAutomationWithoutTeamStatus: '/company/updateFinalNegotationAutomationWithoutTeamStatus',
    updateFinalNegotationAutomationWithoutTeamUsers: '/company/updateFinalNegotationAutomationWithoutTeamUsers',
    updateFinalNegotationAutomationWithoutTeamAssignedTo: '/company/updateFinalNegotationAutomationWithoutTeamAssignedTo',
    getAllTeamLeads: '/company/getAllTeamLeads',
    updateNewLeadReEngagement: '/company/updateNewLeadReEngagement',
    getNewLeadReEngagmentUsers: '/company/getNewLeadReEngagmentUsers',
    saveNewLeadReEngagmentUsers: '/company/saveNewLeadReEngagmentUsers',
    getDeadLeadReEngagmentUsers: '/company/getDeadLeadReEngagmentUsers',
    saveDeadLeadReEngagmentUsers: '/company/saveDeadLeadReEngagmentUsers',
    reVisitTagAutomation: '/company/reVisitTagAutomation',
    insertTagValidityAutomation: '/company/insertTagValidityAutomation',
    getTagValidityAutomation: '/company/getTagValidityAutomation',
    updateTagValidityAutomation: '/company/updateTagValidityAutomation',
    deleteTagValidityAutomation: '/company/deleteTagValidityAutomation',


    // kyc 
    updateKycDocuments: '/company/updateCompanyKyc',
    verifyOTP: '/company/verifyKyc',
    updateInterests: '/company/updateInterests',
    getKycStatus: '/company/getKycStatus',

    // rera verification
    getReraDocumentByState: '/company/getReraDocumentByState',
    insertAndUpdateReraDocument: '/company/insertAndUpdateReraDocument',
    insertEventRegisteration: '/company/insertEventRegisteration',
    getAllReraDocuments: '/company/getAllReraDocuments',

    // project dashboard
    getProjectsForCRM: '/projects/getProjectsForCRM',
    addToList: '/projects/addToUserList',
    getProjectsDetailForCRM: '/projects/getProjectsDetailForCRM',
    insertProjectRegisteration: '/projects/insertProjectRegisteration',
    downloaAlldMedia: '/projects/downloadallMediaForCRM',

    // Project creation
    insertProjectBasicDetail: '/projects/crm/insertProjectBasicDetail',
    getProjectBasicDetailsByUUID: '/projects/crm/getProjectBasicDetailsByUUID',
    updateBasicDetailsByUUID: '/projects/crm/updateBasicDetailsByUUID',
    insertConfiguration: '/projects/crm/insertConfiguration',
    getProjectConfigurations: '/projects/crm/getProjectConfigurations',
    getConfigForMultipleProjects: '/projects/getConfigForMultipleProjects',
    updateConfigurationByUUID: '/projects/crm/updateConfigurationByUUID',
    updateLocation: '/projects/crm/updateLocation',
    updateAmenitiesAndBrancByUUID: '/projects/crm/updateAmenitiesAndBrancByUUID',
    updateDeveloperDetailsByUUID: '/projects/crm/updateDeveloperDetailsByUUID',
    updateOfferesByUUID: '/projects/crm/updateOfferesByUUID',
    insertBankDetail: '/projects/crm/insertBankDetail',
    getProjectBankDetails: '/projects/crm/getProjectBankDetails',
    updateBankDetailByUUID: '/projects/crm/updateBankDetailByUUID',
    insertPaymentPlan: '/projects/crm/insertPaymentPlan',
    getProjectPaymentPlans: '/projects/crm/getProjectPaymentPlans',
    updatePaymentPlanByUUID: '/projects/crm/updatePaymentPlanByUUID',
    insertProjectMedia: '/projects/crm/insertProjectMedia',
    getProjectMedia: '/projects/crm/getProjectMedia',
    updateProjectMedia: '/projects/crm/updateProjectMedia',
    getAmenities: '/public/getAmenities',
    getAmenitiesWithImage: '/public/getAmenitiesWithImage',
    getUnitType: '/public/getUnitType',
    getPreferredTenant: '/public/getPreferredTenant',
    getMediaName: '/public/getMediaName',
    getDocumentNames: '/public/getDocumentNames',
    insertProjectAdditionalCost: '/projects/crm/insertProjectAdditionalCost',
    getProjectProjectAdditionalCosts: '/projects/crm/getProjectProjectAdditionalCosts',
    updateProjectAdditionalCostByUUID: '/projects/crm/updateProjectAdditionalCostByUUID',
    updateProjectAdditionalDetailsByUUID: '/projects/crm/updateProjectAdditionalDetailsByUUID',
    deleteConfigurationByUUID: '/projects/crm/deleteConfigurationByUUID',
    deleteMediaByUUID: '/projects/crm/deleteMediaByUUID',
    deletePaymentPlanByUUID: '/projects/crm/deletePaymentPlanByUUID',
    deleteBankDetailByUUID: '/projects/crm/deleteBankDetailByUUID',
    deleteAdditionalCostByUUID: '/projects/crm/deleteAdditionalCostByUUID',
    getProjectSpecifications: '/projects/crm/getProjectSpecifications',
    getPaymentPlanMilestonesByProjectId: '/projects/crm/getPaymentPlanMilestonesByProjectId',
    getPaymentPlanMilestones: '/projects/crm/getPaymentPlanMilestones',
    getMilestoneDetail: '/projects/crm/getMilestoneDetail',
    insertPaymentMilestone: '/projects/insertPaymentMilestone',
    getPaymentMilestone: '/projects/getPaymentMilestone',
    updateProjectSpecification: '/projects/crm/updateProjectSpecification',
    deleteProjectSpecifications: '/projects/crm/deleteProjectSpecifications',
    insertBrokerage: '/projectBrokers/insertBrokerage',
    findAllBrokerageForProject: '/projectBrokers/findAllBrokerageForProject',
    updateBrokerageSlabByUUID: '/projectBrokers/updateBrokerageByUUID',
    deleteBrokerageByUUID: '/projectBrokers/deleteBrokerageByUUID',
    insertOffer: '/projectOffers/insertOffer',
    findOffersByprojectId: '/projectOffers/findOffersByprojectId',
    updateOfferByUUID: '/projectOffers/updateOfferByUUID',
    deleteOfferByUUID: '/projectOffers/deleteOfferByUUID',
    getProjectBlocks: '/projects/getProjectBlocks',
    getInventoryUnits: '/projects/getInventoryUnits',

    // Pricing Module
    insertPricingEOI: '/projects/crm/insertPricingEOI',
    getPricingEOI: '/projects/crm/getPricingEOI',
    insertPricingBasePrice: '/projects/crm/insertPricingBasePrice',
    getPricingBasePrice: '/projects/crm/getPricingBasePrice',
    getMultiplePriceingBasePrice: '/projects/crm/getMultiplePriceingBasePrice',
    insertPricingAmenityCharges: '/projects/crm/insertPricingAmenityCharges',
    getPricingAmenityCharges: '/projects/crm/getPricingAmenityCharges',
    insertPricingStampDuty: '/projects/crm/insertPricingStampDuty',
    getPricingStampDuty: '/projects/crm/getPricingStampDuty',
    insertPricingRegisterationCharges: '/projects/crm/insertPricingRegisterationCharges',
    getPricingRegisterationCharges: '/projects/crm/getPricingRegisterationCharges',
    insertPricingLegalCharges: '/projects/crm/insertPricingLegalCharges',
    getPricingLegalCharges: '/projects/crm/getPricingLegalCharges',
    insertPricingEDCCharges: '/projects/crm/insertPricingEDCCharges',
    getPricingEDCCharges: '/projects/crm/getPricingEDCCharges',
    insertPricingIDCCharges: '/projects/crm/insertPricingIDCCharges',
    getPricingIDCCharges: '/projects/crm/getPricingIDCCharges',
    insertPricingIFDCCharges: '/projects/crm/insertPricingIFDCCharges',
    getPricingIFDCCharges: '/projects/crm/getPricingIFDCCharges',
    insertPricingGSTCharges: '/projects/crm/insertPricingGSTCharges',
    getPricingGSTCharges: '/projects/crm/getPricingGSTCharges',
    insertPricingDelayedInterest: '/projects/crm/insertPricingDelayedInterest',
    getPricingDelayedInterest: '/projects/crm/getPricingDelayedInterest',
    insertPricingBooking: '/projects/crm/insertPricingBooking',
    getPricingBooking: '/projects/crm/getPricingBooking',
    insertPricingPLCCharges: '/projects/crm/insertPricingPLCCharges',
    getPricingPLCCharges: '/projects/crm/getPricingPLCCharges',

    changeAmenityPriceInclusion: '/projects/crm/changeAmenityPriceInclusion',
    changeStampDutyInclusion: '/projects/crm/changeStampDutyInclusion',
    changeRegisterationChargeInclusion: '/projects/crm/changeRegisterationChargeInclusion',
    changeEDCChargeInclusion: '/projects/crm/changeEDCChargeInclusion',
    changeIDCChargeInclusion: '/projects/crm/changeIDCChargeInclusion',
    changeIFDCChargeInclusion: '/projects/crm/changeIFDCChargeInclusion',
    changeGSTInclusion: '/projects/crm/changeGSTInclusion',
    changeLegalChargeInclusion: '/projects/crm/changeLegalChargeInclusion',
    changePLCChargeInclusion: '/projects/crm/changePLCChargeInclusion',


    // Update
    updatePricingEOIByUUID: '/projects/crm/updatePricingEOIByUUID',
    updatePricingBasePriceByUUID: '/projects/crm/updatePricingBasePriceByUUID',
    updatePricingAmenityChargesByUUID: '/projects/crm/updatePricingAmenityChargesByUUID',
    updatePricingEDCChargesByUUID: '/projects/crm/updatePricingEDCChargesByUUID',
    updatePricingIDCChargesByUUID: '/projects/crm/updatePricingIDCChargesByUUID',
    updatePricingIFDCChargesByUUID: '/projects/crm/updatePricingIFDCChargesByUUID',
    updatePricingStampDutyByUUID: '/projects/crm/updatePricingStampDutyByUUID',
    updatePricingRegisterationChargesByUUID: '/projects/crm/updatePricingRegisterationChargesByUUID',
    updatePricingLegalChargesByUUID: '/projects/crm/updatePricingLegalChargesByUUID',
    updatePricingGSTChargesByUUID: '/projects/crm/updatePricingGSTChargesByUUID',
    updatePricingDelayedInterestByUUID: '/projects/crm/updatePricingDelayedInterestByUUID',
    updatePricingBookingByUUID: '/projects/crm/updatePricingBookingByUUID',
    updatePricingPLCChargesByUUID: '/projects/crm/updatePricingPLCChargesByUUID',

    // Get 
    getPricingEOIByUUID: '/projects/crm/getPricingEOIByUUID',
    getPricingBasePriceByUUID: '/projects/crm/getPricingBasePriceByUUID',
    getPricingAmenityChargesByUUID: '/projects/crm/getPricingAmenityChargesByUUID',
    getPricingEDCChargesByUUID: '/projects/crm/getPricingEDCChargesByUUID',
    getPricingIDCChargesByUUID: '/projects/crm/getPricingIDCChargesByUUID',
    getPricingIFDCChargesByUUID: '/projects/crm/getPricingIFDCChargesByUUID',
    getPricingBookingByUUID: '/projects/crm/getPricingBookingByUUID',
    getPricingPLCChargesByUUID: '/projects/crm/getPricingPLCChargesByUUID',

    // Team
    getAllTeams: '/teams/getAll',
    insertTeam: '/teams/insertTeam',
    updateTeam: '/teams/updateTeam',
    addToProject: '/teams/addToProject',
    getTeamUsers: '/teams/getTeamUsers',
    updateTeamLead: '/teams/updateTeamLead',
    deleteTeamFromProject: '/teams/deleteTeamFromProject',
    getProjectTeams: '/teams/getProjectTeams',
    getTeamDetail: '/teams/getTeamDetail',
    deleteTeamUser: '/teams/deleteTeamUser',

    // Listing Project

    insertBasicDetail: '/projects/listings/insertProjectBasicDetail',
    updateBasicDetailsByUUID: '/projects/listings/updateBasicDetailsByUUID',

    insertProjectHighlights: '/projects/listings/insertProjectHighlights',
    updateProjectHighlights: '/projects/listings/updateProjectHighlights',
    getProjectHighlights: '/projects/listings/getProjectHighlights',

    insertProjectBuilderInfo: '/projects/listings/insertProjectBuilderInfo',
    updateProjectBuilderInfo: '/projects/listings/updateProjectBuilderInfo',
    getProjectBuilderInfo: '/projects/listings/getProjectBuilderInfo',

    insertProjectSEOInfo: '/projects/listings/insertProjectSEOInfo',
    updateProjectSEOInfo: '/projects/listings/updateProjectSEOInfo',
    getProjectSEOInfo: '/projects/listings/getProjectSEOInfo',

    insertProjectReviews: '/projects/listings/insertProjectReviews',
    updateProjectReviews: '/projects/listings/updateProjectReviews',
    getProjectReviews: '/projects/listings/getProjectReviews',

    getProjectFaqs: '/projects/listings/getProjectFaqs',
    setProjectFaq: '/projects/listings/setProjectFaq',

    setProjectSeoInfo: '/projects/listings/setProjectSeoInfo',
    getProjectSEOInfo: '/projects/listings/getProjectSEOInfo',

    insertProjectLocationAdvantages: '/projects/listings/insertProjectLocationAdvantages',
    updateProjectLocationAdvantages: '/projects/listings/updateProjectLocationAdvantages',
    getProjectLocationAdvantages: '/projects/listings/getProjectLocationAdvantages',

    insertProjectSpecifications: '/projects/crm/insertProjectSpecifications',
    insertProjectMedia: '/projects/insertProjectMedia',
    getListingProjects: '/projects/listings/getProjects',
    getProjectDetails: '/projects/listings/getProjectDetails',
    updateProjectStatusByUUID: '/projects/listings/updateProjectStatusByUUID',
    updateBasicDetailsByUUID: '/projects/listings/updateBasicDetailsByUUID',
    saveListingFloorPlanDescription: '/projects/listings/saveListingFloorPlanDescription',
    updateConfigurationByUUID: '/projects/listings/updateConfigurationByUUID',

    insertProjectRera: '/projects/listings/insertProjectRera',
    getProjectRera: '/projects/listings/getProjectRera',
    updateProjectReraByUUID: '/projects/listings/updateProjectReraByUUID',
    deleteProjectRera: '/projects/listings/deleteProjectRera'
  },
  customer: {
    updateByKeyValue: "/customer/update",
    updateByKeyValue2: "/customer/updateByKeyValue2",
    updateBudget: "/customer/updateBudget",
    getEnums: "/customer/getEnums",
    updateCustomer: "/customer/update",
  },
  tags: {
    addTagToLead: "/tags/addTagToLead",
    getTagsByLeadUUID: "/tags/getTagsByLeadUUID",
    removeTagFromLead: "/tags/removeTagFromLead",
  },
  notes: {
    insert: "/notes/insert",
    getAllByLeadUUID: "/notes/getAllByLeadUUID",
    update: "/notes/update",
    removeFromLead: "/notes/removeFromLead",
  },
  followups: {
    insert: "/followups/insert",
    getAllByLeadUUID: "/followups/getAllByLeadUUID",
    update: "/followups/update",
    removeFromLead: "/followups/removeFromLead",
    canCreateFollowup: "/followups/canCreateFollowup",
    getActiveFollowupByLeadId: "/followups/getActiveFollowupByLeadId",
  },
  siteVisits: {
    insert: "/siteVisits/insert",
    getAllByLeadUUID: "/siteVisits/getAllByLeadUUID",
    update: "/siteVisits/update",
    removeFromLead: "/siteVisits/removeFromLead",
    canCreateSV: "/siteVisits/canCreate",
    markCompletedByUUID: "/siteVisits/markCompletedByUUID",
    markCancelledByUUID: '/siteVisits/markCancelledByUUID'
  },
  public: {
    isValidInvite: "/public/isValidInvite",
    companySignup: "/public/companySignup",
    getDeadReasons: "/public/getDeadReasons",
    userLogin: "/auth/login",
    isValidInvite: "/public/isValidInvite",
    getStates: "/public/getStates",
    getCities: "/public/getAllCities",
    getAllCities: '/public/getAllCities',
    getCitiesFromState: "/public/getCitiesByState",
    verifyEmail: "/public/verifyEmail",
    forgotPassword: "/public/forgotPassword",
    forgotPasswordChange: "/public/forgotPasswordChange",
    sendOTP: '/public/sendVerifyEmailOtp',
    verifyOTP: '/public/verifySignupEmail',
    resendOTP: '/public/resendVerifyEmailOtp',
    setSignupPassword: '/public/setSignupPassword',
    getSignupEmail: '/public/getSingupEmail',
    uploadFiles: '/public/getS3Url',
    sendForgotPasswordOTPEmail: '/public/sendForgotPasswordOTPEmail',
    forgotPasswordChangeOTP: '/public/forgotPasswordChangeOTP',
    getS3SignedURL: '/workshop/getS3SignedURL',
  },
  auth: {
    getRolePermissions: '/auth/getRolePermissions',
    verifyOtpForLogin: '/auth/verifyOtpForLogin',
    sendOtpForLogin: '/auth/sendOtpForLogin',
  },

  routing: {
    getUsersForIntegration: "/routingConfig/get",
    markLeadInActive: "/routingConfig/markInactiveByUUID",
    markLeadActive: "/routingConfig/markActiveByUUID",
    updateProjects: '/routingConfig/updateProjects'
  },
  analytics: {
    leadCountByStatus: '/analytics/getLeadCountWithStatus',
    leadCountBySource: '/analytics/getLeadCountWithSource',
    leadCountByIntent: '/analytics/getLeadCountByIntent',
    leadCountByAssignedTo: '/analytics/getLeadCountByAssignedTo',
    leadCountByProject: '/analytics/getLeadCountByProject',
    leadCountByProjectWithStatus: '/analytics/getLeadCountByProjectWithStatus',
    leadCountByProjectWithSource: '/analytics/getLeadCountByProjectWithSource',
    deadLeadCount: '/analytics/getDeadLeadCount',
    bookingLeadCount: '/analytics/getBookings',
    getEventsCount: '/analytics/getEventsCount',
    deadLeadCountByReason: '/analytics/getDeadLeadCountbyReason',
    employeeInsights: '/analytics/getEmployeeInsights',
    countForConversionAnalysis: '/analytics/getCountForConversionAnalysis',
    employeeEventAnalysis: '/analytics/getEmployeeEventAnalysis',
    getAllAdsByCompanyId: '/facebook/getAllAdsByCompanyId',
    getSourcongManagerToCpAnalysis: '/analytics/getSourcongManagerToCpAnalysis',
    getCpLeadAnalysis: '/analytics/getCpLeadAnalysis',
    getLeadsAnalysisBySource: '/analytics/getLeadsAnalysisBySource',
    getLeadsAnalysisByClosingManager: '/analytics/getLeadsAnalysisByClosingManager',
    getNotConnectedLeadsAnalysisByReason: '/analytics/getNotConnectedLeadsAnalysisByReason'
  },

  integration: {
    housingIntegration: '/integrations/createHousingIntegration',
    getHousingIntegration: '/integrations/getHousingIntegrations',
    markHousingActive: '/integrations/markHousingIntegrationActive',
    markHousingInActive: '/integrations/markHousingIntegrationInActive',
    viewHousingIntegration: '/integrations/viewHousingIntegration',
    updateHousingIntegration: '/integrations/updateHousingIntegration',
    getHousingProfiles: '/integrations/getHousingProfiles',
    housingToggleUnmappedLeads: '/integrations/housingToggleUnmappedLeads',
    getHousingIntegrationRouting: '/integrations/getHousingIntegrationRouting',

    // Magicbricks
    magicbricksIntegration: '/integrations/createMagicbricksIntegration',
    getMagicbricksIntegration: '/integrations/getMagicbricksIntegrations',
    markMagicbricksActive: '/integrations/markMagicbricksIntegrationActive',
    markMagicbricksInActive: '/integrations/markMagicbricksIntegrationInActive',
    viewMagicbricksIntegration: '/integrations/viewMagicbricksIntegration',
    updateMagicbricksIntegration: '/integrations/updateMagicbricksIntegration',
    getMagicbricksProfiles: '/integrations/getMagicbricksProfiles',
    magicbricksToggleUnmappedLeads: '/integrations/magicbricksToggleUnmappedLeads',
    getMagicbricksIntegrationRouting: '/integrations/getMagicbricksIntegrationRouting',

    // 99 Acres

    create99AcresIntegration: '/integrations/create99AcresIntegration',
    get99AcresIntegration: '/integrations/get99AcresIntegrations',
    get99AcresProfiles: '/integrations/get99AcresProfiles',
    mark99AcresActive: '/integrations/mark99AcresIntegrationActive',
    mark99AcresInActive: '/integrations/mark99AcresIntegrationInActive',
    view99AcresIntegration: '/integrations/view99AcresIntegration',
    update99AcresIntegration: '/integrations/update99AcresIntegration',
    get99AcresProfiles: '/integrations/get99AcresProfiles',
    acresToggleUnmappedLeads: '/integrations/acresToggleUnmappedLeads',
    get99AcersIntegrationRouting: '/integrations/get99AcersIntegrationRouting'
  },

  // Facebook 
  facebook: {
    getLoginApi: '/facebook/getLoginUrl',
    generateAccessToken: '/facebook/generateAccessToken',
    getLinkedAccounts: '/facebook/getLinkedAccounts',
    getPages: '/facebook/getPages',
    getPageForms: '/facebook/getPageForms',
    getCRMValues: '/facebook/getCrmValues',
    createIntegration: '/facebook/saveIntegration',
    getIntegrations: '/facebook/getIntegratedPages',
    udateActiveState: '/facebook/updateIntegratedPageStatus',
    getFormMapping: '/facebook/getMappingsByFormId',
    getPageFormByIntegrationId: '/facebook/getPageFormsByIntegrationId',
    updateIntegration: '/facebook/updateIntegration',
    getInetgrationsForms: '/facebook/getIntegratedForms',
    getIntegratedPageToEdit: '/facebook/getIntegratedPageToEdit',
    sendtestLead: '/facebook/sendTestLeads',
    importPreviousLeads: '/facebook/importPreviousLeads',
    getUnprocessedLeads: '/facebook/getUnprocessedLeads',
    resolveError: '/facebook/resolveError',
    getImportJobs: '/facebook/getImportJobs',
    getErrorInfo: '/facebook/getErrorInfo',
    deleteIntergration: '/facebook/deleteIntegratedPage',
    updateIntegratedFormActiveStateByUUID: '/facebook/updateIntegratedFormStatus',
    deleteAccount: '/facebook/deleteUser',
    getFormMappingRouting: '/facebook/getFormMappingRouting'
  },

  // IVR Integration
  ivr: {
    getMyOperatorApikey: '/ivr/getMyOperatorApikey',
    generateMyOperatorApikey: '/ivr/generateMyOperatorApikey',
    addMyOperatorIntegration: '/ivr/addMyOperatorIntegration',
    addUserMapping: '/ivr/addUserMapping',
    getIvrIntegrations: '/ivr/getIvrIntegrations',
    getIvrUserMapping: '/ivr/getIvrUserMapping',
    getIvrIntegrationByUUID: '/ivr/getIvrIntegrationByUUID',
    updateMyOperatorIntegration: '/ivr/updateMyOperatorIntegration',
    getCallLogs: '/webhooks/getCallLogs',
    getCallLogStats: '/webhooks/getCallLogStats',

    addKnowlarityIntegration: '/knowlarityIvr/addKnowlarityIntegration',
    addKnowlarityUserMapping: '/knowlarityIvr/addKnowlarityUserMapping',
    addKnowlarityUserCallerIDMapping: '/knowlarityIvr/addKnowlarityCallerId',
    getKnowlarityIvrIntegrations: '/knowlarityIvr/getKnowlarityIvrIntegrations',
    getKnowlarityIvrUserMapping: '/knowlarityIvr/getKnowlarityIvrUserMapping',
    getKnowlarityIvrUserCallerIdMapping: '/knowlarityIvr/getKnowlarityIvrCallerId',
    getKnowlarityIvrIntegrationByUUID: '/knowlarityIvr/getKnowlarityIvrIntegrationByUUID',
    updateKnowlarityIntegration: '/knowlarityIvr/updateKnowlarityIntegration',
    getKnowlarityIvrCallerIdByCompanyId: '/knowlarityIvr/getKnowlarityIvrCallerIdByCompanyId',
    getKnowlarityCallLogs: '/webhooks/getKnowlarityCallLogs',
    getKnowlarityCallLogStats: '/webhooks/getKnowlarityCallLogStats'
  },

  csv: {
    uploadCSV: '/importJob/createJob',
    getReport: '/importJob/getUploadReport',
    getCsvValues: '/importJob/getValuesToMatch',
    getAllCities: '/public/getAllCities',
    insertMappingData: '/importJob/insertMapping',
    getAllJobs: '/importJob/getAll',
    saveConfig: '/importJob/saveConfigByJobId',
    processCSV: '/importJob/processCSV',
    deleteCSV: '/importJob/markDeleted',
    createExport: '/company/createExport',
    getExportLeadsCount: '/company/getExportLeadsCount',
    getExportJobs: '/company/getAllExportJobs'
  },
  workshops: {
    getAllWorkshops: "/workshop/getAllLiveWorkshops",
    getWorkshopById: "/workshop/getWorkshopById",
    getWorkshopMentorsById: "/workshop/getWorkshopMentorForUser"
  },
  guide: {
    get: '/guides/getAll'
  },
  courses: {
    get: '/courses/getActiveCourses',
    getCourseById: '/courses/getCourseByUUID',
    getCourseContent: '/courses/getCourseContent',
    getAllFaqs: '/courses/getAllFaqs',
    getLcActiveCategories: '/courses/getLcActiveCategories'
  },

  subscriptions: {
    insert: '/subscription/insertPaymentPlan',
    getAll: '/subscription/getAllPaymentPlans',
    updatePaymentPlan: '/subscription/updatePaymentPlan'
  },

  launchPad: {
    get: '/launchpad/getEventsForCRM',
    insertEventRegisteration: '/launchpad/insertEventRegisteration',
    sendOTP: '/launchpad/sendOtpToJoinEvent',
    todaysLaunchpad: 'launchpad/getTodayEventsForCRM'
  },
  enroll: {
    enrollUser: "/workshop/addUserToWorkshop/",
    enrolledUser: "/workshop/checkIfUserIsEnrolled/"
  },

  // Roles
  roles: {
    create: '/roles/create',
    getMasterPermissions: '/roles/get_master_permissions',
    setPermissions: '/roles/set_permissions',
    updateRolesPermissions: '/roles/updateRolesPermissions',
    getExistingUsers: '/roles/get_user_to_add',
    addExistingUser: '/roles/add_user',
    addNewUser: '/roles/invite_new_user',
    getUsers: '/roles/get_users',
    addProject: '/roles/add_user_project',
    addUserTeam: '/roles/add_user_team',
    addReportingUser: '/roles/add_user_reporting',
    cancelInvite: '/roles/cancel_user_invite',
    updateRoleBasicDetails: '/roles/udpate_role_basic_detail',
    getMasterRoles: '/roles/get_master_roles',
    deletedRoleByUUID: '/roles/deleteRoleByUUID',
    getRoleDetail: '/roles/role_detail',
    updateTeamLead: '/roles/update_team_lead',
    generateUserInviteLink: '/company/generateUserInviteLink'
  },

  inventory: {
    // Unit
    getUnits: '/projects/getUnits',
    getUnitsByMultipleProjectIds: '/projects/getUnitsByMultipleProjectIds',
    updateUnitByUuid: '/projects/updateUnit',
    getUnitStatusEnum: '/projects/getUnitStatusEnum',
    getUnitBooking: '/projects/getUnitBooking',
    updateUnitAvailability: '/projects/updateUnitAvailability',

    // Booking 
    // insertBookingDone: '/bookingDone/insert', older
    insertBookingDone: '/bookingDone/broker/insertBasicDetails',
    bookingAddAdditionalInfo: '/bookingDone/broker/addAdditionalInfo',
    setBookingUnitByBookingId: '/bookingDone/setBookingUnitByBookingId',
    setBrokerDetails: '/bookingDone/setBrokerDetails',
    setBookingOtherDetails: '/bookingDone/setBookingOtherDetails',
    getBookingDoneDetails: '/bookingDone/getDetails',
    getBookingDoneDetailsByLeadId: '/bookingDone/getDetailsByLeadId',
    changeBookingApprovalStatus: '/bookingDone/changeApprovalStatus',
    setPaymentPlan: '/bookingDone/setPaymentPlan',
    getUnitPricing: '/projects/crm/getUnitPricing',
    setBookingPricing: '/bookingDone/setBookingPricing',
    saveTransaction: '/bookingDone/saveTransaction',
    getDemandDetail: '/bookingDone/getDemandDetail',
    changeBookingStatus: '/bookingDone/changeBookingStatus',
    updateBookingPreferredUnit: '/bookingDone/setPreferredUnit',
    getLeadByCustomerMobileAndProjectId: '/leads/getLeadByCustomerMobileAndProjectId',

    // EOI
    // insertEOI: '/eoiDone/insert', old
    insertEOI: '/eoiDone/broker/insertBasicDetails',
    addAdditionalInfo: '/eoiDone/broker/addAdditionalInfo',
    setEOIUnitByEOIId: '/eoiDone/setBookingUnitByBookingId',
    setEOIBrokerDetails: '/eoiDone/setBrokerDetails',
    setEOIOtherDetails: '/eoiDone/setBookingOtherDetails',
    getEOIDetails: '/eoiDone/getDetails',
    getDetailsByLeadId: '/eoiDone/getDetailsByLeadId',
    changeEOIApprovalStatus: '/eoiDone/changeApprovalStatus',
    updatePreferredUnit: '/eoiDone/setPreferredUnit',
    setEOIPricing: '/eoiDone/setBookingPricing',
    setEoiPaymentPlan: '/eoiDone/setPaymentPlan',
    getBookingPaymentMilestonesByEOIId: '/eoiDone/getBookingPaymentMilestonesByEOIId',
    raiseEOIDemand: '/eoiDone/raiseDemand',
    getEOIBookingPricing: '/eoiDone/getBookingPricing',
    getDemandDetailByEOI: '/eoiDone/getDemandDetailByEOI',
    getPendingDemandByEOIId: '/eoiDone/getPendingDemandByBookingId',
    eoiSaveTransaction: '/eoiDone/saveTransaction',
    getEOIForDiscount: '/eoiDone/getEOIForDiscount',
    changeEoiStatus: '/eoiDone/changeEoiStatus',

    // Collections
    getBookingsForCollectionManager: '/bookingDone/getBookingsForCollectionManager',
    getCollectionsByProjectId: '/bookingDone/getCollectionsByProjectId',
    getDemandsByProjectId: '/bookingDone/getDemandsByProjectId',
    getBookingPaymentMilestonesByBookingId: '/bookingDone/getBookingPaymentMilestonesByBookingId',
    raiseDemand: '/bookingDone/raiseDemand',
    getCollectionManagerStats: '/bookingDone/getCollectionManagerStats',
    getPendingDemandByBookingId: '/bookingDone/getPendingDemandByBookingId',
    addCollection: '/bookingDone/addCollection',
    getCollectionDetails: '/bookingDone/getCollectionDetails',
    updateCollectionStatus: '/bookingDone/updateCollectionStatus',
    getCollectionByBookingId: '/bookingDone/getCollectionByBookingId',
    getRaisedDemands: '/bookingDone/getRaisedDemands',
    getPaymentLedger: '/bookingDone/getPaymentLedger',
    addDebitTransaction: '/bookingDone/addDebitTransaction',
    getBankDetails: '/projects/getBankDetails',
    updateDemand: '/bookingDone/updateDemand',
    getMilestoneByDemand: '/bookingDone/getMilestoneByDemand',
    getCollectionDetailStat: '/bookingDone/getCollectionDetailStat',
    getTransactionProof: '/bookingDone/getTransactionProof',
    generateDemandPdf: '/bookingDone/generateDemandPdf',
    getAllBookingDocs: '/bookingDone/getAllBookingDocs',
    addPDC: '/bookingDone/addPDC',
    createConstructionUpdate: '/constructionUpdates/createConstructionUpdate',
    getConstructionUpdate: '/constructionUpdates/getConstructionUpdate',
    updateConstructionUpdate: '/constructionUpdates/updateConstructionUpdate',
    uploadCustomerBookingDocs: "/bookingDone/uploadCustomerBookingDocs",
    deleteLeadBookingDocs: '/bookingDone/deleteLeadBookingDocs'

  },
  // Broker
  broker: {
    getAllBrokersSV: '/siteVisits/getAllBrokersSV',
    leadCheckforBroker: '/leads/leadCheckforBroker',
    getBrokersForAnalytics: '/company/getBrokersForAnalytics',
    getAllBrokerCompanyUsers: '/company/getAllBrokerCompanyUsers',
    getAllSvInBrokerCompany: '/siteVisits/getAllSvInBrokerCompany',
    getSvByBrokerId: '/siteVisits/getSvByBrokerId',
    insertLead: '/leads/broker/insertLead',
    statusUpdate: '/siteVisits/broker/statusUpdate',
    brokerProjectListing: "/projects/brokerProjectListing",
    deleteBrokerSV: '/siteVisits/deleteBrokerSV',
    getAssignedCPs: '/company/getAssignedCPs',
    sendWhatsappInvitation: '/company/sendWhatsappInvitation',
    getClosingManagerByProjectId: '/company/getClosingManagerByProjectId',
    getProjectByUserAccess: '/projects/getProjectByUserAccess'
  },
  customer: {
    insertNews: '/news/insertNews',
    updateNews: '/news/updateNews',
    getAllNewsCRM: '/news/getAllNewsCRM',
    getNewsCRM: '/news/getNewsCRM',
    deleteNews: '/news/deleteNews',
    getAppCustomers: '/customer/getAppCustomers',
    getNewsTags: '/news/getNewsTagsCRM',
    getS3PresignedUrl: '/public/getS3PresignedUrl',
    addReferralIncentiveCreative: '/customer/addReferralIncentiveCreative',
    getAllCustomerReferrals: '/customer/getAllCustomerReferrals',
    getReferralIncentiveCreative: '/customer/crm/getReferralIncentiveCreative',
    toggleReferralIncentiveCreativeStatusByUUID: '/customer/toggleReferralIncentiveCreativeStatusByUUID',
    getResaleRequests: '/customer/getResaleRequests',
    updateResaleRequestVisibilityByUUID: '/customer/updateResaleRequestVisibilityByUUID',
    submitResaleRequestByPropacity: '/customer/submitResaleRequestByPropacity',
    insertNoteByResaleRequestUUID: '/customer/insertNoteByResaleRequestUUID',
    getNotesByResaleRequestUUID: '/customer/getNotesByResaleRequestUUID',
    getNotesByReferralUUID: '/customer/getNotesByReferralUUID',
    insertNoteByReferralUUID: '/customer/insertNoteByReferralUUID',
    insertCustomerAppFaq: '/customer/insertCustomerAppFaq',
    updateFaqById: '/customer/updateFaqById',
    deleteFaqById: '/customer/deleteFaqById',
    getCustomerAppFaqCrm: '/customer/getCustomerAppFaqCrm'
  }

};

