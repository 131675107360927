import React, { useEffect, useState } from 'react'

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
// import InputText from '../../../components/InputGroup/InputText';
// import TextEditor from '../Editor';
import { toast } from 'react-toastify';
import TextEditor from '../../../ListingProjects/Editor';
import InputText from '../../../../components/InputGroup/InputText';
import RightModal from '../../../../components/Modals/RightModal';
import { insertCustomerAppFaq, updateFaqById } from '../../../../services/private/customerApp.service';

const AddFAQModal = ({ show, closeModal, getAllFaqs, selectedFaq }) => {
    const [faqs, setFaqs] = useState({
        question: '',
        answer: ''
    })
    const [error, setError] = useState(false);

    useEffect(() => {
        if (Object.keys(selectedFaq).length > 0) {
            setFaqs({
                question: selectedFaq.question,
                answer: selectedFaq.answer,
            })
        }
    }, [selectedFaq]);

    const handleSubmit = async () => {
        // setAllFaqs(faqs);  
        if (!faqs.answer || !faqs.question) {
            setError(true);
            return
        } else {
            setError(false);
        }
        let res = {}
        if (selectedFaq?.uuid) {
            res = await updateFaqById(selectedFaq?.uuid, faqs);
        } else {
            res = await insertCustomerAppFaq(faqs);
        }
        if ((res.data.status === 200)) {
            toast.success(res.data.message);
            getAllFaqs();
            closeModal();
        }
    }

    if (Object.keys(selectedFaq).length > 0 && (!faqs.question || !faqs.answer)) {
        return null
    }

    return (
        <RightModal
            show={show}
            closeModal={() => { closeModal(); }}
            modalClass={`configurationModal`}
        >

            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    FAQ
                </h1>
                <button className='border-0 bg-tr' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>

            <div className={`d-flex h-100 overflow-auto flex-column px-20 !mt-4`}>
                <div className=''>
                    <div className='flex flex-col gap-y-4'>
                        <div>
                            {/* <div className='flex justify-between'>
                                <label htmlFor="">Question</label>
                            </div> */}
                            <InputText
                                label={'Question'}
                                type={'text'}
                                placeholder={'Enter Question'}
                                value={faqs.question}
                                onChange={(e) => {
                                    let newFaqs = { ...faqs }
                                    newFaqs['question'] = e.target.value;
                                    setFaqs(newFaqs);
                                }}
                                error={error && !faqs.question ? 'Please Enter Question' : ''}
                                required={true}
                            />
                        </div>
                        <div className={`form-group ${error && !faqs.answer ? 'invalid' : ''}  position-relative`}>
                            <label htmlFor="">Answer <sup>*</sup></label>
                            <TextEditor
                                data={faqs.answer}
                                onChange={(value) => {
                                    let newFaqs = { ...faqs };
                                    newFaqs['answer'] = value;
                                    setFaqs(newFaqs);
                                }}
                            />
                            {error && !faqs.answer && (
                                <>
                                    <div className="input-feedback mt-8">Please Enter Answer
                                    </div>
                                </>

                            )}
                        </div>

                    </div>

                </div>
            </div>
            <div className={`d-flex flex-row align-items-center justify-content-between border-top !pt-3 border-color px-20 righmodalfooter !mb-4`}>
                <button type='button' className='outline-btn w-1/2 !mr-4' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button
                    type='button'
                    onClick={handleSubmit}
                    className='pr-btn w-1/2'
                >
                    {selectedFaq?.uuid ? 'Update' : 'Submit'}
                </button>
            </div>
        </RightModal>
    )
}

export default AddFAQModal;