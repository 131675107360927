import React, { useEffect, useState } from 'react'
import { getAllCustomerReferrals, getNotesByReferralUUID, insertNoteByReferralUUID } from '../../services/private/customerApp.service';
import LeadsFooterBar from '../LeadsManager/LeadsFooterBar';
import AddRemarksModal from '../PropacityPlus/Modal/AddRemarksModal';
import TooltipText from '../../components/Tooltip';
import { toast } from 'react-toastify';
import Projects from '../../components/Custom Table/TableColumns/Projects';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ReferredUser = ({
    searchText,
    getAppUser,
    showingResults,
    setShowingResults,
    currentPage,
    setCurrentPage,
    leadsCount,
    maxPage,
    appUsers
}) => {
    const [remarksList, setRemarksList] = useState([]);
    const [showRemarksModal, setShowRemarksModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({})

    useEffect(() => {
        getAppUser()
    }, [showingResults, currentPage, searchText]);

    const getRemarks = async (uuid) => {
        const res = await getNotesByReferralUUID(uuid);
        if (res.data.status === 200) {
            setRemarksList(res.data.data)
            setShowRemarksModal(true);
        }
    }

    const handleInsertRemarks = async (payload) => {
        const res = await insertNoteByReferralUUID(selectedItem.uuid, { note: payload })
        if (res.data.status === 200) {
            toast.success(res.data.message);
            setShowRemarksModal(false);
            getAppUser();
        }
    }

    return (
        <div className="urer-table pr-0">
            <div className="def-table !h-[75vh] pr-0">
                <table className='table'>
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Name</th>
                            <th>Mobile</th>
                            <th>Referred By</th>
                            <th>Project</th>
                            <th>Remarks</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            appUsers?.length > 0 && appUsers.map((user, index) => (
                                <tr key={user.uuid}>
                                    <td>{index + 1}</td>
                                    <td>{user?.referred_name || '-'}</td>
                                    <td>{user?.mobile || '-'}</td>
                                    <td>
                                        {user?.submitted_by?.length > 25 ?
                                            <TooltipText title={user?.submitted_by}>
                                                <div className='truncate w-[200px]'>
                                                    {user?.submitted_by}
                                                </div>
                                            </TooltipText> :
                                            user?.submitted_by || '-'
                                        }
                                    </td>
                                    <td>
                                        <div className='d-flex flex-row flex-nowrap align-itmes-center project-td'>
                                            <>
                                                <span className='text-capitalize'>{user.project_details?.length > 0 ? <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip id="button-tooltip" >
                                                            <div className='info-msg'>
                                                                {/* <h4>Total Leads</h4>*/}
                                                                <p className='text-capitalize'>{user.project_details[0].name}</p>
                                                            </div>
                                                        </Tooltip >
                                                    }
                                                >
                                                    <span className='three-dots cursor-pointer'>{user.project_details[0].name}</span>
                                                </OverlayTrigger > : '-'}
                                                </span>
                                                {
                                                    user.project_details?.length > 1 ? <div className="tag-dropdown" >
                                                        <button className="border-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            +{user.project_details?.length - 1}
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            {user.project_details.map((item, index) => {
                                                                return <a key={index} className="dropdown-item text-capitalize"  >{item.name}
                                                                    {/* {index == 0 ? <span className='drop-tag'>Primary</span> : ''} */}
                                                                </a>

                                                            })}
                                                        </div>
                                                    </div> :
                                                        " "}
                                            </>
                                        </div>
                                    </td>
                                    <td>
                                        {
                                            user?.latest_note && <TooltipText title={'Edit/Show Remarks'}>
                                                <div
                                                    className='truncate w-[200px] cursor-pointer'
                                                    onClick={() => {
                                                        setSelectedItem(user)
                                                        getRemarks(user?.uuid)
                                                    }}
                                                >{user?.latest_note || user?.remarks}</div>
                                            </TooltipText>
                                        }
                                        {
                                            !user?.latest_note && <span className="border rounded-lg flex cursor-pointer w-20 hover:bg-black200 items-center justify-center" onClick={() => {
                                                setSelectedItem(user)
                                                getRemarks(user?.uuid)
                                                // setShowRemarksModal(true);
                                            }}>
                                                + Add
                                            </span>
                                        }
                                    </td>
                                    <td className="dropdown view-more sticky ">
                                        {user?.lead_uuid && <>
                                            <button
                                                className="bg-tr border-0"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z"
                                                        fill="#B5B5BE"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z"
                                                        fill="#B5B5BE"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z"
                                                        fill="#B5B5BE"
                                                    />
                                                </svg>
                                            </button>
                                            <div
                                                className="dropdown-menu dropdown-menu-right w-auto !p-3"
                                                aria-labelledby="dropdownMenuButton"
                                            >
                                                <a
                                                    className="dropdown-item"
                                                    data-toggle="modal"
                                                    data-target="#exampleModal"
                                                    onClick={
                                                        () => {
                                                            window.open(`/leads/${user?.lead_uuid}`)
                                                        }
                                                    }
                                                >
                                                    View Leads
                                                </a>
                                            </div>
                                        </>}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                {appUsers?.length === 0 && <div className='d-flex justify-content-center flex-column text-center h-[50vh]'>
                    <div className='flex items-center flex-col'>
                        <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                        <h2 className="po black fz16 fw-po-medium mb-8"> No Referral Added</h2>
                        {/* <p className='text-center black-dark-600 fz14 mb-0'>Please add new leads to view</p>*/}
                    </div>
                </div>}
                <LeadsFooterBar
                    showingResults={showingResults}
                    setShowingResults={setShowingResults}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    maxPage={maxPage}
                    leadsCount={leadsCount}
                    classname='!basis-0'
                />
                {
                    showRemarksModal && <AddRemarksModal
                        show={showRemarksModal}
                        closeModal={() => {
                            setSelectedItem({})
                            setShowRemarksModal(false)
                            setRemarksList([]);
                        }}
                        item={selectedItem}
                        handleSubmit={handleInsertRemarks}
                        remarksList={remarksList}
                        setRemarksList={setRemarksList}
                    // handleUpdateRemark={handleUpdateRemark}
                    />
                }
            </div>
        </div>
    )
}

export default ReferredUser