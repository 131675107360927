import React, { useEffect, useState } from 'react'
// import { uploadFile } from '../../services/public/public.service';
import style from '../../../modules/Settings/Projects/style.module.css';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import InputText from '../../../components/InputGroup/InputText';
import InputTextarea from '../../../components/InputGroup/InputTextarea';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
// import { addBanner, getBannerByUUID, updateBannerByUUID } from '../../services/private/company.service';
import { ReactComponent as BackICon } from '../../../assets/icons/back.svg';
import InputSelect from '../../../components/InputGroup/InputSelect';
import { getS3SignedURL, uploadFile } from '../../../services/public/public.service';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import { getCities } from '../../../services/private/csv.service';
import TextEditor from '../../ListingProjects/Editor';
import { getNewsCRM, getNewsTags, insertNews, updateNews } from '../../../services/private/customerApp.service';
import { getProjects } from '../../../services/private/company.service';
import ConfirmDeletionCustom from '../../../components/Modals/ConfirmDeletionCustom';
import { Oval } from 'react-loader-spinner';
import axios from 'axios';
import { API_URLS } from '../../../config/apiUrl';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

const customStyles = {
    option: (styles, state) => ({
        ...styles,
        zIndex: 99,
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: (base, state) => ({
        ...base,
        zIndex: 99,
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
};
const VIEDO_EXTENSION = ['video/mp4', 'video/webm'];

const AddNew = () => {
    const [mediaUrl, setMediaUrl] = useState('');
    const [mobileImg, setMobileImg] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [city, setCity] = useState([]);
    const [project, setProject] = useState([])
    const [projectList, setProjectList] = useState([]);
    const [error, setError] = useState(false);
    const [marketImpact, setMarketImpact] = useState('Up');
    const [allTags, setAllTags] = useState([]);
    const [tag, setTag] = useState('');
    const [loading, setLoading] = useState(false);
    const [sourceName, setSourceName] = useState('');
    const [sourceURL, setSourceURL] = useState('');
    const [showVideo, setShowVideo] = useState(false);
    const [fileType, setFileType] = useState('');
    const [uploadingProgress, setUploadingProgress] = useState('')

    const { uuid } = useParams();
    const history = useHistory();

    const getNewsByUUID = async () => {
        const res = await getNewsCRM(uuid);
        if (res.data.status === 200) {
            setMediaUrl(res.data.data?.media[0].url)
            setTitle(res.data.data?.title);
            setDescription(res.data.data?.paragraph);
            const mapped = projectList.filter(i => {
                return res.data.data?.project_mapping.includes(i.label);
            })
            setProject(mapped[0].value);
            setCity(res.data.data?.city_mapping.map(i => ({ label: i, value: i })))
            setMarketImpact(res.data.data?.market_impact)
            setTag(res.data.data?.news_tag_id)
            setSourceName(res.data.data?.source_name);
            setSourceURL(res.data.data?.source_url)
        }
    }

    const loadOptions = (city) => {
        return getCities(city)
            .then((res) => {
                if (res.data.status === 200) {
                    const cities = res.data?.data.map((city) => {
                        return {
                            label: city.name,
                            value: city.name
                        }
                    });
                    return cities;
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const getAllProjects = async () => {
        const res = await getProjects('');
        if (res.data.status === 200) {
            let mappedProjects = res.data.data.projects.map(item => ({ ...item, label: item.name, value: item.id }))

            setProjectList(mappedProjects);
        }
    }

    useEffect(() => {
        if (uuid && projectList.length) {
            getNewsByUUID();
        }
    }, [uuid, projectList]);

    const getAllNewsTag = async () => {
        const res = await getNewsTags();
        if (res.data.status === 200) {
            const mapped = res.data.data.map(i => ({ ...i, label: i.value, value: i.id }))
            setAllTags(mapped)
        }
    }

    useEffect(() => {
        getAllProjects()
        getAllNewsTag();
    }, []);

    const handleSubmitVideo = async (file) => {

        try {
            // Request a signed URL from the backend             
            const response = await axios.post(`${API_URLS.baseurl}/public/getS3PresignedUrl`, {
                key: `video_${new Date().getTime()}.mp4`,
                contentType: file.type,
            });
            const signedUrl = response.data.data.url;

            // Upload the image to S3 using the signed URL
            await axios.put(signedUrl, file, {
                headers: {
                    'Content-Type': file.type,
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setUploadingProgress(percentCompleted);
                }
            }).then(res => {
                setMediaUrl(signedUrl.split('?')[0])
                // console.log(res)                    
            });

        } catch (error) {
            console.error('Error uploading the file:', error);
        }
    }

    const handleOnChangeWebImg = async (e) => {
        const file = e.target.files[0];
        if (VIEDO_EXTENSION.includes(file.type)) {
            setFileType(file.type)
            handleSubmitVideo(file);
            return
        }
        const formData = new FormData();
        formData.append('file', file)
        const response = await uploadFile(formData);

        if (response.data.status === 200) {
            setMediaUrl(response.data.data.s3_url);
        }
    }

    const handleSubmit = async () => {
        if (!title || city.length === 0 || project.length === 0 || !mediaUrl || !description || !tag || !sourceName || !sourceURL) {
            setError(true);
            return
        } else {
            setError(false);
        }
        const payload = {
            title: title,
            project_mapping: [project],
            city_mapping: city.map(i => i.value),
            paragraph: description,
            media: [{
                url: mediaUrl
            }],
            market_impact: marketImpact,
            news_tag_id: tag,
            source: {
                source_name: sourceName,
                url: sourceURL
            }
        }
        let res = {}
        setLoading(true)
        if (uuid) {
            res = await updateNews(uuid, payload);
        } else {
            res = await insertNews(payload);
        }

        if (res.data.status === 200) {
            setLoading(false)
            toast.success(res.data.message)
            // getOffers();  
            history.push('/settings/customer-news/news')
        }
    }

    return (
        <div className="w-100 d-lg-flex  flex-col h-full">
            <div className="!px-5 !py-4 !border-b !border-grayLight">
                <div className="row d-flex d-flex flex-md-fill flex-wrap mb-2">
                    <div className="col-md-7 col-6 d-flex flex-md-fill flex-column">
                        <h1 className="text-xl m-0 black fw-po-medium flex items-center">
                            <button onClick={() => {
                                history.push('/settings/customer-news/news')
                            }} className='mr-2'>
                                <BackICon />
                            </button>
                            {uuid ? 'Edit' : 'Create'} News
                        </h1>
                        {/* <p className="fz16 black-dark-700 mb-0 d-md-block d-none">Manage all subscriptions for plus app users</p> */}
                    </div>
                </div>
            </div>
            <div className={`${style.creatproject} overflow-auto !px-5 !pt-5`}>
                <div className='w-100'>
                    <div className={`${style.basic_form} basic_form`}>
                        <InputText
                            label='Title'
                            placeholder={'Enter Title'}
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                            error={error && !title ? 'Please enter title' : ''}
                            required={true}
                        />
                        <div className={`form-group ${(error && city.length === 0) ? 'invalid' : ''}  position-relative`}>
                            <label htmlFor="">City <sup>*</sup></label>
                            <AsyncCreatableSelect
                                cacheOptions
                                loadOptions={loadOptions}
                                defaultOptions
                                value={city}
                                placeholder='Search and Select City'
                                styles={customStyles}
                                isMulti={true}
                                onChange={(e) => {
                                    // if (e)
                                    //     setCity(e)
                                    setCity(e)
                                }}
                                menuPosition={'fixed'}
                                isClearable
                            />
                            {error && city.length === 0 && (
                                <div className="input-feedback mt-8">
                                    Please select city
                                </div>
                            )}
                        </div>

                        <InputSelect
                            inputclass='!mt-0'
                            placeholder='Select Project'
                            value={project ? projectList.find(i => i.value === project) : ''}
                            onChange={(value) => {
                                setProject(value.value)
                            }}
                            options={projectList}
                            label={'Project'}
                            required={true}
                            error={error && project.length === 0 ? 'Please select project' : ''}
                        />
                        <div className='form-group'>
                            <label>Market Impact<sup>*</sup></label>
                            <div>
                                <label className='mr-4'>
                                    <input
                                        type='radio'
                                        name='marketImpact'
                                        value='Up'
                                        checked={marketImpact === 'Up'}
                                        onChange={() => setMarketImpact('Up')}
                                    /> Up
                                </label>
                                <label>
                                    <input
                                        type='radio'
                                        name='marketImpact'
                                        value='Down'
                                        checked={marketImpact === 'Down'}
                                        onChange={() => setMarketImpact('Down')}
                                    /> Down
                                </label>
                            </div>
                        </div>
                        <div className={`form-group ${(error && !tag) ? 'invalid' : ''}  position-relative`}>
                            <label htmlFor="">Tag <sup>*</sup></label>
                            <CreatableSelect
                                placeholder='Search & Select Tag'
                                menuPlacement='auto'
                                maxMenuHeight={150}
                                onCreateOption={(value) => {
                                    let val = value.charAt(0).toUpperCase() + value.slice(1)
                                    setTag(val)
                                    const newList = [...allTags];
                                    newList.push({ label: val, value: val })
                                    setAllTags([...newList]);
                                }}
                                onChange={(value) => {
                                    setTag(value.value)
                                }}
                                value={tag ? allTags.find(i => i.value === tag) : ''}
                                options={allTags}

                            />
                            {error && !tag && (
                                <div className="input-feedback mt-8">
                                    Please select tag
                                </div>
                            )}
                        </div>
                        <InputText
                            label='Source Name'
                            placeholder={'Enter Source Name'}
                            value={sourceName}
                            onChange={e => setSourceName(e.target.value)}
                            error={error && !sourceName ? 'Please enter source name ' : ''}
                            required={true}
                        />
                        <InputText
                            label='Source URL'
                            placeholder={'Enter source URL'}
                            value={sourceURL}
                            onChange={e => setSourceURL(e.target.value)}
                            error={error && !sourceURL ? 'Please enter source url' : ''}
                            required={true}
                        />
                        <div></div>
                        <div className={`form-group ${error && !mediaUrl ? 'invalid' : ''}  position-relative`}>
                            <div className='fz14 black-dark-700 mb-8 inter d-flex justify-content-between'>
                                <label>Image/Video <sup>*</sup></label>
                                {uploadingProgress && <span>{uploadingProgress} %</span>}
                            </div>
                            <div className={`${style.upload}`}>
                                {mediaUrl && <img src={mediaUrl} alt="" className='w-100 h-100' style={{ objectFit: 'contain' }} />}
                                <input type="file" className='d-none' name='developer-logo' id='web-img' accept='image/*, video/*' onChange={handleOnChangeWebImg} />
                                {!mediaUrl && <div className={`${style.uplaodbtngrp}`}>
                                    <button
                                        type='button'
                                        className={`${style.uploadbtn} pr-btn inter flex items-center`}
                                        onClick={() => {
                                            document.getElementById('web-img').click()
                                        }}
                                    ><PluswhiteIcon />Upload</button>
                                </div>}
                                <div className={`${style.hoverbox}`}>
                                    {mediaUrl.includes('.mp4') && <button
                                        type='button'
                                        className={`${style.replacebtn} pr-btn inter flex items-center`}
                                        onClick={() => {
                                            setShowVideo(true);
                                        }}
                                    >Play</button>}
                                    <button
                                        type='button'
                                        className={`${style.replacebtn} pr-btn inter flex items-center`}
                                        onClick={() => {
                                            document.getElementById('web-img').click()
                                        }}
                                    ><PluswhiteIcon />Replace</button>
                                    <button
                                        type='button'
                                        className={`${style.delbtn} inter border-0 bg-white`}
                                        onClick={() => setMediaUrl('')}
                                    ><TrashIcon /></button>
                                </div>
                            </div>
                            {error && !mediaUrl && (
                                <div className="input-feedback mt-8">
                                    Please upload media
                                </div>
                            )}
                        </div>

                        <div className={`form-group ${error && !mediaUrl ? 'invalid' : ''}  position-relative col-span-2`}>
                            <label htmlFor="">Description <sup>*</sup></label>
                            <TextEditor data={description} onChange={(value) => setDescription(value)} />
                            {error && !description && (
                                <div className="input-feedback mt-8">
                                    Please enter description
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
            <div className={`${style.createprojectfooter} border-top border-color d-flex justify-content-end align-items-center !mt-4`}>
                {/* <button
                    type='button'
                    className={`${style.cancelbtn}`}
                    onClick={() => {
                        history.push(`/projects/create-projects/mediaupload/${uuid}?pt=${propertyType}&tt=${transactionType}`);
                    }}
                ><LeftIcon />Previous </button> */}
                {!loading && <button className={`${style.savebtn}`} onClick={handleSubmit} disabled={loading}>
                    {uuid ? 'Update' : 'Save'}
                </button>}
                {loading && <button className={`${style.savebtn}`}>
                    <Oval
                        height={22}
                        width={22}
                        color="#FFFFFF"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#FFFFFF"
                        strokeWidth={4}
                        strokeWidthSecondary={4}
                    />
                </button>}
            </div>
            {
                showVideo && <Modal size="lg" show={showVideo} onHide={() => setShowVideo(false)} backdrop="static" fullscreen centered dialogClassName="modal-radius">
                    <a onClick={() => setShowVideo(false)} className='cursor-pointer close position-absolute video-close'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#ffff"></path></svg></a>
                    {/* <ReactPlayer
                        url={workshop.recording_url || videoURL}
                        controls={true}
                        className='learnvideo'
                        width={'100%'}
                        height={'auto'}
                        onDuration={handleGetDuration}
                        onProgress={handleGetCurrentTime}
                    /> */}
                    <video controls autoPlay id='learning-center' controlsList="nodownload" preload='preloadauto' className='learnvideo' width={'100%'} height={'auto'}>
                        <source src={mediaUrl} type="video/mp4" />
                    </video>
                </Modal>
            }
        </div>
    )
}

export default AddNew;