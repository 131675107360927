import React, { useEffect, useState } from 'react';
import Tabs from '../../components/HOC/Tabs';
import ReferredUser from './ReferredUser';
import Customer from './Customer';
import ResaleRequest from './ResaleRequest';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as SearchCloseIcon } from '../../assets/icons/searchClose.svg';
import { getAllCustomerReferrals, getAppCustomers, getResaleRequests } from '../../services/private/customerApp.service';

const tabList = [
    { name: 'Customer' },
    { name: 'Referred User' },
    { name: 'Resale Requests' }
]

const CustomerDashboard = () => {
    const [selectedTab, setSelectedTab] = useState({ name: 'Customer' })
    const [searchText, setSearchText] = useState('');
    const [showingResults, setShowingResults] = useState(10);
    const [currentPage, setCurrentPage] = useState(1)
    const [leadsCount, setLeadsCount] = useState('');
    const [maxPage, setMaxPage] = useState(1);

    const [showingReferralResults, setShowingReferralResults] = useState(10);
    const [currentReferralPage, setCurrentReferralPage] = useState(1)
    const [leadsReferralCount, setLeadsReferralCount] = useState('');
    const [maxReferralPage, setMaxReferalPage] = useState(1);

    const [showingResaleResults, setShowingResaleResults] = useState(10);
    const [currentResalePage, setCurrentResalePage] = useState(1)
    const [leadsResaleCount, setLeadsResaleCount] = useState('');
    const [maxResalePage, setMaxResalePage] = useState(1);

    const [appUsers, setAppUsers] = useState([]);
    const [referralUsers, setReferralUsers] = useState([]);
    const [resaleRequests, setResaleRequests] = useState([])

    const [tabRowCount, setTabRowCount] = useState({
        'Customer': 0,
        'Referred User': 0,
        'Resale Requests': 0
    })

    const getAppUser = async () => {
        const payload = {
            pageNumber: currentPage,
            perPage: showingResults,
            searchText: searchText
        }
        try {
            const res = await getAppCustomers(payload);
            if (res.data.status === 200) {
                setLeadsCount(res.data.data?.total_customers)
                const totalPages = Math.ceil(
                    Number(res.data.data?.total_customers) / showingResults
                );
                setMaxPage(totalPages);
                setAppUsers(res.data.data?.customers);
            }
        } catch (err) {
            setLeadsCount('0')
            setAppUsers([]);
        }
    }
    const getReferralUser = async () => {
        const payload = {
            pageNumber: currentReferralPage,
            perPage: showingReferralResults,
            searchText: searchText
        }
        try {
            const res = await getAllCustomerReferrals(payload);
            if (res.data.status === 200) {
                setLeadsReferralCount(res.data.data?.totalCount || '0')
                const totalPages = Math.ceil(
                    Number(res.data.data?.totalCount) / showingReferralResults
                );
                setMaxReferalPage(totalPages);
                setReferralUsers(res.data.data?.referrals);
            }
        } catch (err) {
            setLeadsReferralCount('0')
            setReferralUsers([]);
        }
    }

    const getAllRequests = async () => {
        const payload = {
            pageNumber: currentResalePage,
            perPage: showingResaleResults,
            searchText: searchText
        }
        try {
            const res = await getResaleRequests(payload);
            if (res.data.status === 200) {
                setLeadsResaleCount(res.data.data?.totalCount)
                const totalPages = Math.ceil(
                    Number(res.data.data?.totalCount) / showingResaleResults
                );
                setMaxResalePage(totalPages);
                setResaleRequests(res.data.data?.resaleRequests);
            }
        } catch (err) {
            setLeadsResaleCount('0');
            setResaleRequests([])
        }
    }

    useEffect(() => {
        // if (leadsReferralCount && leadsCount && leadsResaleCount) {
        setTabRowCount({
            'Customer': leadsCount,
            'Referred User': leadsReferralCount,
            'Resale Requests': leadsResaleCount
        })
        // }
    }, [leadsReferralCount, leadsResaleCount, leadsCount]);

    useEffect(() => {
        getAppUser();
    }, [showingResults, currentPage])

    useEffect(() => {
        getReferralUser();
    }, [showingReferralResults, currentReferralPage]);

    useEffect(() => {
        getAllRequests();
    }, [showingResaleResults, currentResalePage])

    useEffect(() => {
        if (searchText) {
            if (selectedTab.name === 'Customer') {
                getAppUser();
            }
            if (selectedTab.name === 'Referred User') {
                getReferralUser();
            }
            if (selectedTab.name === 'Resale Requests') {
                getAllRequests();
            }
        } else {
            getAppUser();
            getReferralUser();
            getAllRequests();
        }
    }, [searchText, selectedTab]);

    useEffect(() => {
        setSearchText('');
    }, [selectedTab]);

    return (
        <div className="main-section show-new-leads flex flex-col !pl-0 !pb-0 !pr-0">
            <div className="!px-5 !border-b !border-grayLight">
                <div className="row d-flex d-flex flex-md-fill flex-wrap mb-2">
                    <div className="col-md-7 col-6 d-flex flex-md-fill flex-column">
                        <h1 className="text-xl m-0 black fw-po-medium">Customer Dashboard</h1>
                        {/* <p className="fz16 black-dark-700 mb-0 d-md-block d-none">Manage all subscriptions for plus app users</p> */}
                    </div>
                    <div className="col-md-5 col-6 d-flex justify-content-end align-items-md-end aling-items-center d-flex flex-md-fill">
                        <div>
                            <div className={`border rounded px-2 py-1 flex mr-3 items-center ${searchText ? 'border-primary' : ''} `}>
                                <SearchIcon className='mr-2' />
                                <input
                                    type="text"
                                    placeholder={'Search For Users'}
                                    className=' w-100 focus:outline-none text-sm'
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                                {searchText &&
                                    <div className="cursor-pointer" onClick={() => setSearchText('')}>
                                        <SearchCloseIcon />
                                    </div>}
                            </div>
                        </div>

                    </div>
                </div>
                <Tabs
                    tabList={tabList}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    tabName={selectedTab}
                    tabRowCount={tabRowCount}
                />
            </div>
            <div className='!mt-4 !px-5 '>
                {selectedTab.name === 'Customer' && <Customer
                    searchText={searchText}
                    appUsers={appUsers}
                    getAppUser={getAppUser}
                    showingResults={showingResults}
                    setShowingResults={setShowingResults}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    maxPage={maxPage}
                    leadsCount={leadsCount}
                />}
                {selectedTab.name === 'Referred User' && <ReferredUser
                    searchText={searchText}
                    appUsers={referralUsers}
                    getAppUser={getReferralUser}
                    showingResults={showingReferralResults}
                    setShowingResults={setShowingReferralResults}
                    currentPage={currentReferralPage}
                    setCurrentPage={setCurrentReferralPage}
                    maxPage={maxReferralPage}
                    leadsCount={leadsReferralCount}
                />}
                {selectedTab.name === 'Resale Requests' && <ResaleRequest
                    searchText={searchText}
                    allRequests={resaleRequests}
                    getAllRequests={getAllRequests}
                    showingResults={showingResaleResults}
                    setShowingResults={setShowingResaleResults}
                    currentPage={currentResalePage}
                    setCurrentPage={setCurrentResalePage}
                    maxPage={maxResalePage}
                    leadsCount={leadsResaleCount}
                />}
            </div>
        </div>
    )
}

export default CustomerDashboard